import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
 
class FunToolsList extends Component {
  render() {
    return (
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <a href="https://www.pamperedchef.com/shop/Bakeware/Pastry+%26+Baking+Tools/Powdered+Sugar+Shaker/100023">Flour Shaker</a> <br/>
                    Made for powdered sugar (so be sure to label it!), but works with flour too! Great for flouring proofing baskets, counters, cake tins, etc.
                </ListGroup.Item>
                <ListGroup.Item>
                    Feeling extra? <br/>
                    I backed <a href="https://www.kickstarter.com/projects/stranddesign/fourneau-grande-and-levee?ref=user_menu">this</a> on Kickstarter - haven't received it yet but happy to chat more when I do. The levee seems like an awesome way to not burn yourself!
                </ListGroup.Item>
            </ListGroup>
    );
  }
}
 
export default FunToolsList;