import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';

class ToolsList extends Component {
    render() {
        return (
            <ListGroup variant="flush">
                 <ListGroup.Item>
                     Basics - you'll want kitchen towels, oven mitts, and parchment paper.
                </ListGroup.Item>
                <ListGroup.Item>
                    Dutch Ovens - <a href="https://www.target.com/p/lodge-5qt-cast-iron-dutch-oven/">Lodge - 5qt</a> or <a href="https://www.williams-sonoma.com/products/le-creuset-signature-round-dutch-oven/">Le Creuset - 5.5qt</a>
                    <br />Enameled vs traditional doesn't seem to matter (though season it with a high-heat oil to prevent smokiness when you go to bake your loaves). I've found these sizes to work well.
                </ListGroup.Item>
                <ListGroup.Item>
                    Lame - <a href="https://wiremonkey.com/collections/ufo-lames">Wire Monkey UFO</a> <br />
                    Replacement blades - <a href="https://www.amazon.com/Astra-Superior-Platinum-Double-Blades/dp/B001OXTHJC/ref=sr_1_10?dchild=1&keywords=astra+razor+blades&qid=1590118037&s=beauty&sr=1-10">Astra</a> <br />
                    <a href="https://en.wikipedia.org/wiki/Lame_(kitchen_tool)">What is a Lame?</a> <br />
                     If you're just getting started - a sharp kitchen knife will do, though you might not have clean results. You can also use a razor blade without the lame.
                </ListGroup.Item>
                <ListGroup.Item>
                    <a href="https://www.amazon.com/Inch-Banneton-Proofing-Basket-Set/dp/B01GM4UZJI">Proofing Baskets (Bannetons) - 9 in</a> <br/>
                    If you're just getting started - a bowl or colander with a floured towel will do the trick!
                </ListGroup.Item>
                <ListGroup.Item>
                    <a href="https://www.amazon.com/Etekcity-Multifunction-Stainless-Batteries-Included/dp/B0113UZJE2/ref=sr_1_3?dchild=1&keywords=kitchen+scale&qid=1590119120&sr=8-3">Kitchen Scale</a> <br/>
                    This one I recommend buying even if you're just getting started - measuring by volume can very a LOT in just one cup, and can compound throughout a recipe. 200g of flour is always 200g of flour!
                </ListGroup.Item>
                <ListGroup.Item>
                    <a href="https://www.amazon.com/bench-scraper/s?k=bench+scraper">Bench scraper</a> <br/>
                    Another must-have. Some come with bannetons or lames depending on where you shop. Any will do, though I prefer a firm one for cutting/shaping dough.
                </ListGroup.Item>
                <ListGroup.Item>
                    <a href="https://www.ikea.com/us/en/p/tomat-spray-bottle-white-40371254/">Spray bottle</a> <br/>
                    You can probably do without this at first, but spritzing when you put your loaves in will help to create steam in the dutch oven during baking.
                </ListGroup.Item>
            </ListGroup>
        );
    }
}

export default ToolsList;