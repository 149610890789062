import React, { Component } from 'react';
import { Accordion, Card, Image } from 'react-bootstrap';
import ToolsList from './ToolsList';
import ResourcesList from './ResourcesList';
import FunToolsList from './FunToolsList';

class SourdoughTab extends Component {
  render() {
    return (
      <div>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Resources
          </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <ResourcesList/>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Tools - recommended
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <ToolsList/>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              Tools - for fun
          </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <FunToolsList/>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }
}

export default SourdoughTab;