import SourdoughTab from './components/SourdoughTab';
import React from 'react';
import comet from './img/Comet.jpg';
import cometMobile from './img/comet-mobile.jpg'
import './App.css';
import { Tabs, Tab, Image, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faGithub, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function App() {
  return (
    <div>
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#home" className="title">annaburek.io</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          {/* <Nav.Link href="#sourdough">Sourdough</Nav.Link> */}
          <NavDropdown.Divider/>
          <Nav.Link href="https://www.linkedin.com/in/anna-burek/"><FontAwesomeIcon icon={faLinkedin} /></Nav.Link>
          <Nav.Link href="https://twitter.com/aeburek"><FontAwesomeIcon icon={faTwitter} /></Nav.Link>
          <Nav.Link href="https://www.instagram.com/aeburek/"><FontAwesomeIcon icon={faInstagram} /></Nav.Link>
          <Nav.Link href="https://github.com/aburek"><FontAwesomeIcon icon={faGithub} /></Nav.Link>
        </Nav>
      </Navbar.Collapse>
      </Navbar>

      <header className="App-header">
        <div className="App">
          <Tabs defaultActiveKey="home">
            <Tab eventKey="home" title="Home">
              <Image className="img-responsive" src={comet} alt="Comet the kitter" fluid />
              <Image className="img-responsive mobile" src={cometMobile} fluid/>  
            </Tab>
            <Tab eventKey="sourdough" title="Sourdough">
              <SourdoughTab/>
            </Tab>
          </Tabs>
      </div>
    </header>
    </div>
  );
}
export default App;