import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
 
class ResourcesList extends Component {
  render() {
    return (
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <a href="https://www.amazon.com/Tartine-Bread-Chad-Robertson/dp/0811870413/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=&sr=">Tartine Bread cookbook</a><br/>
                    If you're just getting started - try the Basic Country Bread Tartine has posted their recipe to their <a href="https://tartinebakery.com/stories/country-bread">website</a>, and it was published in the <a href="https://cooking.nytimes.com/recipes/1016277-tartines-country-bread?crlt.pid=camp.zNmW1DcxCrcI&gclid=Cj0KCQjwzZj2BRDVARIsABs3l9JqoAnJsgKAMFnE8vhKaqEAPMmRWJ3Y1rBZds2Vj8C_kgZ4pnwv52YaAgMwEALw_wcB&gclsrc=aw.ds"> New York Times</a> <br/>
                </ListGroup.Item>
                <ListGroup.Item>
                  Feeding your starter - <a href="https://www.kingarthurflour.com/recipes/feeding-and-maintaining-your-sourdough-starter-recipe">King Arthur Flour</a><br/>
                  If I'm not baking often, I store mine in the fridge and feed it once a week.<br/>
                  If I am baking often (2+ times a week) I'll feed it once a day (or twice if I'm trying to time things just right). I use about 50g tepid water and 50g white flour, and store it in a glass or plastic jar on the counter with a loose fitting lid. This keeps out any dust, but still allows it to breathe.
                </ListGroup.Item>
                <ListGroup.Item>
                    Inspiration and tutorials <br/>
                    <a href="https://www.seawolfbakers.com/">Sea Wolf Bakery Seattle</a> <br/>
                    <a href="https://www.instagram.com/bensbreadseattle/">Ben's Bread - Instagram</a> <br/>
                    <a href="https://www.instagram.com/tartinebakery">Tartine Bread - Instagram (check out the tutorials)</a> <br/>
                    <a href="https://www.instagram.com/jenniferlatham/">Jennifer Latham (Head of bread @ Tartine) - Instagram</a> <br/>
                </ListGroup.Item>
            </ListGroup>
    );
  }
}
 
export default ResourcesList;